import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Image from "../../components/image";
import { asset } from '../../helpers/dom.helper';
import { toRoute } from "../../i18n";
import { NAVIGATION_DATA } from "./_data";

const _Submenu = React.memo(({ parent, items }) => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const [expanded, setExpanded] = useState(false)

	return (
		<div className="w-100">
			<Link to={pathname} className="d-flex" onClick={() => setExpanded(!expanded)}>
				<span className="mr-auto">{t(parent.label)}</span>
				<Image
					src={asset(expanded ? '/icon/collapse.svg' : '/icon/arrow-forward.svg')}
					alt={parent.label} width="12" height="6"
					className="w-auto h-auto"
				/>
			</Link>
			<ul className={`list-unstyled pl-3 ${expanded ? 'd-block' : 'd-none'}`}>
				{items.map((it, idx) => (
					<li key={idx}>
						<Link to={toRoute(it.path)}>{t(it.label)}</Link>
					</li>
				))}
			</ul>
		</div>
	)
})

const _Menu = React.memo(() => {
	const { t } = useTranslation()

	return (
		<div className="menu">
			{NAVIGATION_DATA.map((nav, key) => (
				<div className="d-block" key={key}>
					<h6>{t(nav.label)}</h6>

					{nav.items.map((it, idx) => (
						it.items?.length
							? <_Submenu parent={it} items={it.items} key={`${key}${idx}`} />
							: 
							<Link to={toRoute(it.path)} key={`${key}${idx}`}>
								{it.label === 'Seminar' 
																? <> {t('Seminar')} <img src={asset('/images/seminar/grommet.png')} style={{marginRight: '8px',marginLeft: '8px' }} />
																	 <img src={asset('/images/seminar/regis-seminar.png')} style={{width:'30%'}}/> 
																</>   
																: t(it.label)}
								</Link>
					))}

					<hr />
				</div>
			))}

			<Link to={toRoute('/about')}>
				<h6>{t('About Us')}</h6>
			</Link>
			<hr />

			<Link to={toRoute('/juristic-support-team')}>
				<h6>{t('Juristic Support Team')}</h6>
			</Link>
			<hr />

			<Link to={toRoute('/career')}>
				<h6>{t('Careers')}</h6>
			</Link>
			<hr />

			<Link to={toRoute('/contact')}>
				<h6>{t('Contact Us')}</h6>
			</Link>
			<hr />

			<Link to={toRoute('/faq')}>
				<h6>{t('FAQ')}</h6>
			</Link>
			<hr />

			<Link to={toRoute('/videos')}>
				<h6>{t('คู่มือการใช้งาน')}</h6>
			</Link>
			<hr />

			<Link to={toRoute('/privacy')}>
				<h6>{t('Privacy Policy')}</h6>
			</Link>
			<hr />
		</div>
	)
})

export const MobileNavigation = React.memo(() => {

	const location = useLocation()
	const [show, setShow] = useState(false)

	useEffect(() => {
		setShow((currentValue) => currentValue ? false : currentValue)
	}, [location])

	return (
		<>
			<Image src={asset('/icon/menu.webp')} alt="menu" width="25" height="18" className="menu-toggle" onClick={() => setShow(true)} />
			<div className={`mobile-navigation ${show ? 'show' : ''}`}>
				<Link to={toRoute('/')}>
					<Image src={asset('/images/navigation/logo-silverman.svg')} alt="silverman" width="145" height="42" className="logo" />
				</Link>

				<Image src={asset('/icon/close.svg')} alt="silverman" width="21" height="21" className="navigation-close" onClick={() => setShow(false)} />
				<hr />

				<_Menu />
			</div>
		</>
	)

})
