export const FOOTER_LINKS = [
  {
    label: 'Silverman App',
    url: '#/',
    items: [
      { label: 'Blogs[1]', url: '/informations/3' },
      { label: 'Juristic Knowledge Sources', url: '/informations' },
      { label: 'Juristic Management Manual[1]', url: '/informations/1' },
      { label: 'How to select Juristic Management Company', url: '/informations/2' },
      { label: 'Common Fee Calculation', url: '/informations/4' },
      { label: 'Juristic Management Company’s name lists', url: '/juristic-management-company' }
    ]
  },
  {
    label: 'sitemap:Accounting and Finance',
    url: '#/',
    items: [
      { label: 'sitemap:Financial Statements of Jurisric Person[1]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Balance sheet of Jurisric Person', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Income - Expense statement of Jurisric Person', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Trial Balance of Jurisric Person', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Financial Statements of Jurisric Person[2]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:General Ledger of Jurisric Person', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Fines, Penalties and Surcharges Calcualtion', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Income Collection work', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Invoice', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Receive', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Warning letter', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Notice', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Debt-free Certification[1]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Debt-free Certification[2]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Utility Bills', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:AI System for Checking Silp', url: '/for-juristic-accounting?type=online' },
    ]
  },
  {
    label: 'sitemap:Juristic Person Management',
    url: '#/',
    items: [
      { label: 'sitemap:Juristic Person Committee', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Condominium Juristic Person Committee', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Housing Estate Juristic Person Committee', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Project Detail', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Rules & Regulations', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Register of Co-owners, Residents, Tenants', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Contract Management Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Parcels Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Parcels Management Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Fix Request Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Complaint Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Survey Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:News & Announcement Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Common Areas Booking Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Common Areas Management Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Procurement Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Contact Management Work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Condominium system work', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Village System work', url: '/for-juristic-management?type=property' },
    ]
  },
  {
    label: 'sitemap:Property Management Services[1]',
    url: '#/',
    items: [
      { label: 'sitemap:Condominium Juristic Person Property Management Company', url: '/juristic-management-company' },
      { label: 'sitemap:Housing Estate Juristic Person Property Management Company', url: '/juristic-management-company' },
      { label: 'sitemap:Juristic Person Work Property Management Company', url: '/juristic-management-company' },
      { label: 'sitemap:Building Property Management', url: '/juristic-management-company' },
      { label: 'sitemap:Condominium Work Property Management Company', url: '/juristic-management-company' },
      { label: 'sitemap:Property Management Services[2]', url: '/juristic-management-company' },
      { label: 'sitemap:Juristic Person Manager', url: '/juristic-management-company' },
    ]
  },
  {
    label: 'sitemap:Accounting Software for Juristic',
    url: '#/',
    items: [
      { label: 'sitemap:Juristic Person Accounting Program[1]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Condominium Juristic Person Accounting Program', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Housing Estate Juristic Person Accounting Program', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Condominium Accounting Program[3]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Condominium Accounting Program[1]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Condominium Accounting Program[2]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Housing Estate Accounting Program', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Juristic Person Accounting Program[3]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Juristic Person Accounting Program[2]', url: '/for-juristic-accounting?type=online' },
    ]
  },
  {
    label: 'sitemap:Management Software for Juristic',
    url: '#/',
    items: [
      { label: 'sitemap:Program for Juristic Person[1]', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Juristic Person Management Program', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Program for Condominium', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Condominium Management Program', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Housing Estate Management Program', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Condominium Juristic Person Program[1]', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Program for Property Management', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Village Juristic Person Management Program', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Program for Village', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Program for Housing Estate', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Package Program for Housing Estate', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Condominium Management Program', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Program for Village Juristic Person', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Program for Real Estate', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Condominium Juristic Person Program[2]', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Program for Juristic Person[2]', url: '/for-juristic-management?type=property' },
      { label: 'sitemap:Building Management Program', url: '/for-juristic-management?type=property' },
    ]
  },
  {
    label: 'sitemap:App for Niti[1]',
    url: '#/',
    items: [
      { label: 'sitemap:App for Niti[1]', url: '/for-juristic-accounting?type=online' },
      // { label: 'sitemap:App for Niti[2]', url: '/for-juristic-accounting?type=online' },
      // { label: 'sitemap:App for Niti[3]', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:Application for Juristic Person', url: '/for-juristic-accounting?type=online' },
      { label: 'sitemap:App for Condominium', url: '/for-juristic-accounting?type=condo' },
      { label: 'sitemap:App for Village', url: '/for-juristic-accounting?type=village' },
      { label: 'sitemap:App for Housing Estate', url: '/for-board' },
      { label: 'sitemap:Application for Condominium', url: '/for-juristic-accounting?type=condo' },
      { label: 'sitemap:Application for Village', url: '/for-juristic-accounting?type=village' },
      { label: 'sitemap:App for Residents', url: '/for-resident' },
    ]
  },
  {
    label: 'sitemap:e-meeting',
    url: '#/',
    items: [
      { label: 'sitemap:e-meeting', url: '/foQus' },
      { label: 'sitemap:condo meeting[2]', url: '/foQus' },
      { label: 'sitemap:village meeting[2]', url: '/foQus' },
      { label: 'sitemap:meeting for condo[2]', url: '/foQus' },
      { label: 'sitemap:emeeting', url: '/foQus' },
      { label: 'sitemap:E meeting', url: '/foQus' },
      { label: 'sitemap:E-meeting ', url: '/foQus' },
      { label: 'sitemap:e-meeting system', url: '/foQus' },
      { label: 'sitemap:meeting e-meeting[2]', url: '/foQus' },
      { label: 'sitemap:Online Meeting System (e-meeting)', url: '/foQus' },
      { label: 'sitemap:electronic meeting system', url: '/foQus' },
      { label: 'sitemap:online meeting', url: '/foQus' },
      { label: 'sitemap:electronic meeting[2]', url: '/foQus' },
      { label: 'sitemap:E-AGM', url: '/foQus' },
      { label: 'sitemap:village meeting', url: '/foQus' },
      { label: 'sitemap:condo meeting', url: '/foQus' },
      { label: 'sitemap:meeting system e-meeting[2]', url: '/foQus' },
      { label: 'sitemap:village meeting[3]', url: '/foQus' },
      { label: 'sitemap:condo meeting[3]', url: '/foQus' },
      { label: 'sitemap:Condominium General Meeting', url: '/foQus' },
      { label: 'sitemap:Annual General Meeting by E-Meeting', url: '/foQus' },
      { label: 'sitemap:Condominium Annual General Meeting', url: '/foQus' },
      { label: 'sitemap:Housing Estate Annual General Meeting', url: '/foQus' },
      { label: 'sitemap:General Meeting System via Electronic Media', url: '/foQus' },
    ]
  },
]