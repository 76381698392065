import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import i18n, { toRoute } from "../../i18n";
import { Media, MediaContextProvider } from '../../media';
import { MobileNavigation } from "./mobileNavigation";
import './style.scss';
import { NAVIGATION_DATA } from "./_data";

const _DropdownSubmenu = React.memo(({ parent, items }) => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const [isEnter, setIsEnter] = useState(false)

	return (
		<div
			className="dropdown-submenu"
			onMouseEnter={() => setIsEnter(true)}
			onMouseLeave={() => setIsEnter(false)}
		>
			<Dropdown.Item as={Link} to={pathname} style={parent.style || {}}>{t(parent.label)}</Dropdown.Item>
			<div className={`dropdown-menu dropdown-menu-right ${isEnter ? 'show' : ''}`}>
				{items.map((it, idx) => (
					<Link to={toRoute(it.path)} className="dropdown-item" key={idx}>{t(it.label)}</Link>
				))}
			</div>
		</div>
	)
})

export function Navigation(props) {

	const { t } = useTranslation()
	const { pathname } = useLocation()

	function getUrl(lang) {
		if (lang === 'en') {
			return '/en' + pathname.replace(/^\/en\//, '/')
		}
		return pathname.replace(/^\/en\//, '/')
	}

	return (
		<div id="navigation" className="white">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<nav className="navbar navbar-expand-lg navbar-light">

							<Link to={toRoute("/")}>
								<Image src={asset('/images/navigation/logo-silverman.svg')} alt="silverman" width="145" height="42" className="logo" />
							</Link>

							<div className="switch-language d-flex flex-row">
								<Link to={getUrl()} className={i18n.language === "th" ? "active" : ""}>TH</Link>
								<Link to={getUrl("en")} className={i18n.language === "en" ? "active" : ""}>EN</Link>
							</div>

							<MediaContextProvider>
								<Media lessThan="md">
									<MobileNavigation />
								</Media>
							</MediaContextProvider>

							<div className="collapse navbar-collapse justify-content-end">
								<div className="navbar-nav justify-content-between align-items-center">
									{NAVIGATION_DATA.map((nav, key) => (
										<Dropdown className="nav-item" key={key}>
											<Dropdown.Toggle as={Link} to={pathname} className="nav-link" id={'nav' + key}>
												{t(nav.label)}
												<Image src={asset('/images/navigation/dropdown.svg')} alt="dropdown-icon" width="11" height="11" className="dropdown-icon" />
											</Dropdown.Toggle>
											<Dropdown.Menu align={`${key > (NAVIGATION_DATA.length - 3) ? 'right' : 'left'}`} aria-labelledby={'nav' + key}>
												{nav.items.map((it, idx) => {													
													return (
													it.items?.length
														? <_DropdownSubmenu parent={it} items={it.items} key={`${key}${idx}`} />
														: <Dropdown.Item as={Link} to={toRoute(it.path)} key={`${key}${idx}`} style={it.style || {}}>
															{it.label === 'Seminar' 
																? <> {t('Seminar')} <img src={asset('/images/seminar/grommet.png')} style={{ width: '16px', marginRight: '8px' }} />
																	 <img src={asset('/images/seminar/regis-seminar.png')} style={{width:'23%'}}/> 
																</>   
																: t(it.label)}
														</Dropdown.Item>)
												})}
											</Dropdown.Menu>
										</Dropdown>
									))}

									<div className="nav-item">
										<Link to={toRoute("/register")} className="nav-link text-right">
											<button className="btn btn-primary registration">
												{t("Free Register")}
											</button>
										</Link>
									</div>

								</div>
							</div>

						</nav>
					</div>
				</div>
			</div >
		</div >
	)

}
